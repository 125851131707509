import { Observable } from "rxjs";
import { filter, map, switchMap } from "rxjs/operators";
import { getData } from "api/api.helper";
import { Config } from "services/config/config.interfaces";
import configService from "services/config/config.services";
import { fetchConfig, configFetched } from "store/slices/config.slice";

export const fetchConfigsEpic = (action$: Observable<any>) =>
  action$.pipe(
    filter((action) => fetchConfig.match(action)),    
    switchMap(() => configService.getConfig().pipe(
      getData<Config>(),
      map((response) => configFetched(response))
    )));
  

const configEpic = [fetchConfigsEpic];

export default configEpic;
