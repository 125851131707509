export const statuses = [
	{
		id: true,
		name: "Active",
	},
	{
		id: false,
		name: "Blocked",
	},
];

export const privileges = [
	{ id: 0, role: "Participant" },
	{ id: 1, role: "Orginiser" },
	{ id: 2, role: "Admin" },
];
