import { makeStyles } from "@material-ui/core/styles";

export const useLoginStyles = makeStyles((theme) => ({
  form: {
    "& > *": {
      width: "400px",
      display: "flex",
      flexDirection: "column",
    },
  },
  input: {
    marginBottom: "30px",
  },
  titleText: {
    fontSize: "40px",
    fontWeight: 700,
  },
  subTitle: {
    fontSize: "26px",
  },
  container: {
    padding: "10px",
    display: "flex",
    height: "90vh",
    justifyContent: "center",
    alignItems: "center",
  },
  logo: {
    marginRight: "2rem",
    "& > *": {
      maxWidth: 100,
      maxHeight: 100,
    },
  },
  brandContainer: {
    display: "flex",
    flexDirection: "row",
  },
  leftConatiner: {
    margin: "10px",
    padding: "10px",
    display: "flex",
    paddingRight: "2rem",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  rightConatiner: {
    borderLeft: "solid 2px #eeeeee",
    margin: "10px",
    paddingLeft: "4rem",
    marginLeft: "1rem",
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      borderLeft: "none",
      paddingLeft: 0,
      marginLeft: 0,
    },
  },
  forgottonPassword: {
    textAlign: "right",
    color: "#cacaca",
    marginTop: "2rem",
  },
}));
