import React from "react"
import { Route } from "react-router-dom"

interface RouteWrapperProps {
  component: React.ReactNode
  layout: React.ReactNode
  path: string
}

export const RouteWrapper: React.FC<RouteWrapperProps> = ({
  component: Component,
  layout: Layout,
  ...rest
}: any) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout {...props}>
          <Component {...props} />
        </Layout>
      )}
    />
  )
}
