import { makeStyles } from "@material-ui/core/styles";

const useUserStyles = makeStyles({
	midStyles: {
		display: "flex",
		justifyContent: "center",
	},
	avatarPadding: {
		paddingRight: "25px",
		display: "flex",
		alignItems: "center",
	},
	iconStyles: {
		color: "red",
		overflow: "initial",
	},
});

export default useUserStyles;
