import { makeStyles } from "@material-ui/core/styles";
import { colors, theme, border } from "../../../theme/theme";

const usePanelStyles = makeStyles({
  TitleText: () => ({
    fontSize: "200%",
    fontWeight: "500",
  }),
  SeeMoreText: () => ({
    color: colors.blue,
    textTransform: "uppercase",
    fontSize: "80%",
    fontWeight: "700",
    cursor: "pointer",
  }),
  Container: ({ defaultWidth }) => ({
    display: "flex",
    flexDirection: "column",
    width: defaultWidth,
    padding: ".75rem",
    background: "#ffffff",
    marginBottom: theme.spacing(3),
    ...border,
  }),
  plainRoot: {
    paddingTop: "3px",
    paddingBottom: "3px",
    background: colors.white,
    marginBottom: theme.spacing(3),
    ...border,
  },
  Content: ({ seeMoreActive, defaultHeight }) => ({
    maxHeight: seeMoreActive ? defaultHeight : "",
    overflow: seeMoreActive ? "hidden" : "",
    flexGrow: 1,
  }),
});

export default usePanelStyles;
