import React from "react";
import { Box } from "@material-ui/core";
import DataGrid, {
  SearchPanel,
  Sorting,
  Column,
  Export,
} from "devextreme-react/data-grid";
import useEventTableStyles from "./EventsSummaryTable.styles";
import { Event } from "../../Events/events.interfaces";
import { PlainPanel } from "../../../common/Panel/Panel";
import { Participants } from "../dashboard.interfaces";

interface EventsTableProps {
  events: Event[];
  height?: number | string;
}

const defaultAvatar = "/img/calendar.jpg";

function imgError(e: any) {
  e.onError = "";
  e.target.src = defaultAvatar;
  return true;
}

const EventsTable: React.FC<EventsTableProps> = ({ events, height }) => {
  const classes = useEventTableStyles();

  const cellRender = (user: any) => (
    <img
      alt="Event"
      width={50}
      height={50}
      style={{ borderRadius: 50 }}
      src={user.data.image || defaultAvatar}
      onError={imgError}
    />
  );

  const participantsCellRender = (participants: any) => {
    const participantsString = (participants.value || [])
      .map((participant: Participants) => participant.name)
      .join(", ");
    return <>{participantsString}</>;
  };

  return (
    <>
      <Box className={classes.eventText}>Top Events This Period</Box>
      <PlainPanel>
        <DataGrid
          height={height}
          id="gridContainer"
          dataSource={events}
          allowColumnReordering={true}
          allowColumnResizing={true}
        >
          <Sorting mode="multiple" />
          <SearchPanel visible={true} width={240} placeholder="Search..." />
          <Export enabled={true} />
          <Column caption="Event" cellRender={cellRender} dataField="image" />
          <Column caption="Name" allowSorting={true} dataField="eventName" />
          <Column
            caption="Online/Offline Location"
            allowSorting={true}
            dataField="fullLocation"
          />
          <Column
            caption="Date"
            allowSorting={true}
            dataType="date"
            dataField="eventStartDate"
          />
          <Column
            caption="Engaged Participants"
            dataField="participants"
            cellRender={participantsCellRender}
          />
        </DataGrid>
      </PlainPanel>
    </>
  );
};

export default EventsTable;
