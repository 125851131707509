import { makeStyles } from "@material-ui/core/styles";
import { colors } from "../../../theme/theme";

const useDeleteDialogStyles = makeStyles({
	root: { "& .MuiPaper-root": { overflow: "initial" } },
	iconContainer: {
		display: "flex",
		justifyContent: "center",
	},
	title: {
		borderTop: `1rem solid ${colors.red}`,
		textAlign: "center",
		paddingBottom: 0,
		overflow: "initial",
	},
	iconTitleCircle: {
		display: "flex",
		justifyContent: "center",
		padding: "5px",
		top: "-10px",
		position: "absolute",
		backgroundColor: "#d10000",
		color: "white",
		height: "36px",
		width: "40px",
		borderRadius: "50px",
	},
	iconTitle: {
		size: "20",
	},
	content: {
		marginBottom: 0,
		paddingBottom: 0,
	},
	input: {
		width: 300,
	},
	dialogActions: {
		justifyContent: "center",
		marginTop: 0,
		paddingTop: 0,
		paddingBottom: "1rem",
	},
	titleStyling: {
		display: "default",
	},
	padding: {
		textAlign: "center",
		margin: "10px",
	},
	buttonPadding: {
		display: "block",
		textAlign: "center",
	},
	cancelButton: {
		color: colors.grey,
		border: `1px solid ${colors.grey}`,
		marginRight: "1rem",
		width: 142,
	},
	deleteButton: {
		background: colors.red,
		color: colors.white,
		width: 142,
		"&:hover": {
			backgroundColor: "#ff0000",
			color: colors.white,
		},
		"&.MuiButton-root.Mui-disabled": {
			backgroundColor: colors.redOpacity(0.7),
			color: colors.white,
			opacity: 0.5,
		},
	},
});

export default useDeleteDialogStyles;
