import { createEpicMiddleware, combineEpics } from "redux-observable";
import { catchError } from "rxjs/operators";
import homeEpic from "../components/pages/HomePage/home.epic";
import usersEpic from "../components/pages/UserPage/users.epic";
import configEpic from "store/epics/config.epic";

export const epicMiddleware = createEpicMiddleware();

const epics: any[] = [...usersEpic, ...homeEpic, ...configEpic];

export const rootEpic = (action$: any, store$: any, dependencies: any) =>
  combineEpics(...epics)(action$, store$, dependencies).pipe(
    catchError((error: any, source: any) => {
      console.error(error);
      return source;
    })
  );

// export const rootEpic = combineEpics(...epics);
