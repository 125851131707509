import { Box } from "@material-ui/core";
import Chart, {
  ArgumentAxis,
  CommonSeriesSettings,
  Grid,
  Legend,
  Margin,
  Series,
  Tooltip,
} from "devextreme-react/chart";
import React from "react";
import { biChartColours } from "../../../../constants/colors.constants";
import { ChartableEvent } from "../events.interfaces";
import useEventChartStyles from "./EventsChart.styles";

const eventsCategories = [
  { value: "online", name: "Online" },
  { value: "offline", name: "Offline" },
];

interface EventsChartProps {
  events: ChartableEvent[];
}

const EventsChart: React.FC<EventsChartProps> = ({ events }) => {
  const classes = useEventChartStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.chartWrapper}>
        <Chart palette={biChartColours} dataSource={events}>
          <CommonSeriesSettings argumentField="month" type="line" />
          {eventsCategories.map((item) => (
            <Series key={item.value} valueField={item.value} name={item.name} />
          ))}
          <Margin bottom={20} />
          <ArgumentAxis
            valueMarginsEnabled={false}
            discreteAxisDivisionMode="crossLabels"
          >
            <Grid visible={true} />
          </ArgumentAxis>
          <Legend
            verticalAlignment="bottom"
            horizontalAlignment="center"
            itemTextPosition="bottom"
          />
          <Tooltip enabled={true} />
        </Chart>
      </Box>
    </Box>
  );
};

export default EventsChart;
