import React from "react";
import { Box } from "@material-ui/core";
import { StatsConfig } from "./StatsBrief.interfaces";
import useStatsBriefStyles from "./StatsBrief.styles";

export const StatsBrief: React.FC<StatsConfig> = ({
  title,
  icon: Icon,
  sub,
  iconText,
  disabled,
  difference
}) => {
  const classes = useStatsBriefStyles({ disabled, difference });
  return (
    <Box className={classes.container}>
      <Box className={classes.title}>{title}</Box>
      <Box className={classes.iconText}>
        <Box className={classes.iconTextContainer}>{iconText}</Box>
        <Box className={classes.iconContainer}>
          <Icon />
        </Box>
      </Box>
      <Box className={classes.info}>{sub}</Box>
    </Box>
  );
};
interface StatsListProps {
  statsConfig: StatsConfig[];
}

export const StatsList: React.FC<StatsListProps> = ({ statsConfig = [] }) => {
  const classes = useStatsBriefStyles({});
  return (
    <Box className={classes.statsListContainer}>
      {statsConfig.map(({ id, title, sub, icon, iconText, difference, disabled }) => {
        return (
          <StatsBrief
            key={id}
            id={id}
            disabled={disabled}
            title={title}
            icon={icon}
            sub={sub}
            difference={difference}
            iconText={iconText}
          />
        );
      })}
    </Box>
  );
};
