export const API_ROUTES = {
  AUTH: {
    BASE: "/auth/",
    LOGIN: "login",
    CHECK: "check",
  },
  EVENTS: {
    BASE: "/events/",
    STATS: "stats",
  },
  MEMBERS: {
    BASE: "/members/",
  },
  STATISTICS: {
    BASE: "/statistics/",
  },
  CONFIG: {
    BASE: "/app-config/",
  },
};

export const ROUTES = {
  AUTH: {
    LOGIN: "/login",
  },
};
