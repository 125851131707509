import { createSelector } from "reselect";
import { State } from "../../../store/store.interfaces";
import { UsersState } from "./users.slice";

export const usersSelector = (state: State) => state.users;

export const selectUsers = createSelector(
	usersSelector,
	(users: UsersState) => users.list
);

export const selectIsLoading = createSelector(
	usersSelector,
	(users: UsersState) => users.isLoading
);
