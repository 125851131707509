import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import useDeleteDialogStyles from "./DeleteDialog.styles";
import DeleteIcon from "@material-ui/icons/Delete";

export default function DeleteDialog({
	open,
	onConfirmClose,
	onCancelClose,
	details: Details,
}: any) {
	const [deleteInput, setDeleteInput] = useState("");
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
	const classes = useDeleteDialogStyles();
	const confirmationDelete = "delete";
	return (
		<Dialog
			className={classes.root}
			fullScreen={fullScreen}
			open={open}
			style={{ overflow: "initial" }}
			onClose={onCancelClose}
			aria-labelledby="responsive-dialog-title"
		>
			<Box className={classes.iconContainer}>
				<Box className={classes.iconTitleCircle} id="icon-circle">
					<DeleteIcon className={classes.iconTitle} />
				</Box>
			</Box>
			<DialogTitle className={classes.title} id="responsive-dialog-title">
				{"Delete Account?"}
			</DialogTitle>
			<DialogContent className={classes.content}>
				<DialogContentText>
					<Box className={classes.padding}>
						This account will be permenantly deleted from the system
					</Box>
					{Details && <Details />}
					<Box className={classes.padding}>Type "delete" to confirm</Box>
					<Box className={classes.padding}>
						<TextField
							className={classes.input}
							id="outlined-basic"
							placeholder="Type here"
							variant="outlined"
							onChange={({ target: { value } }) => setDeleteInput(value)}
						/>
					</Box>
				</DialogContentText>
			</DialogContent>
			<DialogActions className={classes.dialogActions}>
				<Box className={classes.buttonPadding}>
					<Button
						className={classes.cancelButton}
						onClick={onCancelClose}
						color="primary"
					>
						Cancel
					</Button>
					<Button
						className={classes.deleteButton}
						onClick={onConfirmClose}
						disabled={deleteInput !== confirmationDelete}
						color="primary"
					>
						Delete
					</Button>
				</Box>
			</DialogActions>
		</Dialog>
	);
}
