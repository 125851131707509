import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "./users.interfaces";

export type UserRecords = Record<string, User>;

export interface UsersState {
  byId: UserRecords;
  list: User[];
  isLoading: boolean;
}

const initialState: UsersState = {
  byId: {},
  list: [],
  isLoading: false,
};

export const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    usersFetched: (state, action: PayloadAction<User[]>) => {
      state.list = action.payload;
      state.isLoading = false;
    },
    deleteUser: (state, action: PayloadAction<User>) => {
      state.list = state.list.filter((user) => user.id !== action.payload.id);
    },
  },
});

export const { setLoading, usersFetched, deleteUser } = userSlice.actions;

export default userSlice.reducer;
