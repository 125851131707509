import API from "../../../api/api.conf";
import { API_ROUTES, ROUTES } from "../../../api/routes.constants";
import Cookies from "universal-cookie";
import { timer, Subscription } from "rxjs";
import { tap, filter } from "rxjs/operators";
import { AUTH_TOKEN } from "../../../constants/cookies.constants";

const cookies = new Cookies();

class AuthService {
  checkSubscription: any;
  checkInterval = 60_000;

  async login(username: string, password: string) {
    try {
      const {
        data: { access_token },
      } = await API.post(`${API_ROUTES.AUTH.BASE}${API_ROUTES.AUTH.LOGIN}`, {
        username,
        password,
      });
      cookies.set(AUTH_TOKEN, access_token);
      return true;
    } catch (e) {
      return false;
    }
  }

  async isAuthenticated() {
    try {
      await API.get(`${API_ROUTES.AUTH.BASE}${API_ROUTES.AUTH.CHECK}`);
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  startCheck() {
    return timer(0, this.checkInterval)
      .pipe(
        filter(() => !window.location.pathname.includes(ROUTES.AUTH.LOGIN)),
        tap(() => console.log("check auth")),
        tap(this.isAuthenticated)
      )
      .subscribe();
  }

  unsubscribeCheck() {
    (this.checkSubscription as Subscription).unsubscribe();
  }
}

const instance = new AuthService();

instance.startCheck();

Object.freeze(instance);

export default instance;
