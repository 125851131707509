import { createSelector } from "reselect";
import { State } from "store/store.interfaces";
import { ConfigState } from "../slices/config.slice";

export const configSelector = (state: State) => state.config;

export const selectCompany = createSelector(
	configSelector,
	(config: ConfigState) => config.company
);

