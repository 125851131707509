import React from "react";
import { UserTable } from "./components/UserTable";

export const UserPage = () => {
  return (
    <div>
      <h1>Members</h1>
      <UserTable />
    </div>
  );
};
