import { Observable } from "rxjs";
import { filter, switchMap, map } from "rxjs/operators";
import qs from "qs";
import { api } from "../../../api/api.conf";
import { getData } from "../../../api/api.helper";
import { API_ROUTES } from "../../../api/routes.constants";
import { getDateRange } from "../../../helpers/date.helpers";
import { fetchStatistics, statisticsFetched, StatisticsRecord } from "./home.slice";

export const homeFetchStatisticsEpic = (action$: Observable<any>) =>
  action$.pipe(
    filter((action) => fetchStatistics.match(action)),
    switchMap(({payload: {fromDate, toDate} }) => {
      const dateRange = getDateRange({fromDate, toDate});
      return api.get(`${API_ROUTES.STATISTICS.BASE}?${qs.stringify(
				dateRange
			)}`).pipe(
      getData<StatisticsRecord>(),
      map((response) => statisticsFetched(response))
    ) }),
    
  );

const homeEpic = [homeFetchStatisticsEpic];

export default homeEpic;
