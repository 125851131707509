import React, { useEffect, useState } from "react";
import { PlainPanel } from "../../common/Panel/Panel";
import styles from "./DashBoard.style";
import { eventsService } from "../Events/events.service";
import { TabPanels } from "../../common/TabPanel/TabPanel";
import { dashboardTabConfig, statsBriefConfig } from "./Dashboard.constants";
import { statisticsService } from './statistics.service';
import { ChartableEvent, Event } from "../Events/events.interfaces";
import EventsTable from "./components/EventsSummaryTable";
import DashboardCriteria from "./components/DashboardCriteria";
import { subDays } from "date-fns";
import { StatsList } from "../../common/StatsBrief/StatsBrief";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { fetchStatistics } from "./home.slice";
import { selectStatistics } from "./home.selectors";

const initialSelectionRange = {
  startDate: subDays(new Date(), 28),
  endDate: new Date(),
  key: "selection",
};

const { Container, MainSection, SideSection, Line } = styles;

export const DashBoard = () => {

  const dispatch = useAppDispatch();

  const statistics = useAppSelector(selectStatistics);

  const [chartableEvent, setChartableEvent] = useState<ChartableEvent[]>([]);

  const [dateSelectionRange, setDateSelectionRange] = useState<any>(
    initialSelectionRange
  );

  const [events, setEvents] = useState<Event[]>([]);
  useEffect(() => {
    const fetchEvents = async () => {
      const eventsData = await eventsService.fetchEvents(
        dateSelectionRange.startDate,
        dateSelectionRange.endDate
      );
      setEvents(eventsData);
      dispatch(fetchStatistics({ fromDate: dateSelectionRange.startDate,
        toDate: dateSelectionRange.endDate}));
      setChartableEvent(eventsService.mapToChartableEvents(eventsData));
    };
    fetchEvents();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateSelectionRange]);

  return (
    <Container>
      <MainSection>
        {" "}
        <DashboardCriteria
          dateSelectionRange={dateSelectionRange}
          onRangeSelection={setDateSelectionRange}
        />
        <PlainPanel>
          <TabPanels tabConfig={dashboardTabConfig(chartableEvent)} />
          <Line />
          {/* Might want to change this function */}
          <StatsList statsConfig={[...statisticsService.transformStatisticToBrief(statistics), ...statsBriefConfig]} />
        </PlainPanel>
        <EventsTable height="450px" events={events} />
      </MainSection>
      <SideSection>
        {/* {panelConfig.map((panel, index) => (
          <Panel key={index} title={panel.title}>
            {panel.content}
          </Panel>
        ))} */}
        {/* <LastHourPanel />
        <LastHourPanel />
        <LastHourPanel /> */}
      </SideSection>
    </Container>
  );
};
