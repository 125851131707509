import { colors } from "../../theme/theme";
import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 240;

const useBaseLayoutStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
    minWidth: 1300,
    background: colors.backgroundGrey,
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  // necessary for content to be below app bar
  toolbar: {
    ...theme.mixins.toolbar,
    display: 'flex',
    justifyContent: 'center',
    padding: 10
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default useBaseLayoutStyles;
