import React, { useEffect } from "react";
import { statuses, privileges } from "../users.constants";
import DataGrid, {
  SearchPanel,
  Column,
  Export,
  Lookup,
  Editing,
  HeaderFilter,
  Button,
} from "devextreme-react/data-grid";
import TagBox from "devextreme-react/tag-box";
import { getUsers, deletetUser, updateUser } from "../users.services";
import { selectUsers } from "../users.selector";
import { usersFetched, setLoading, deleteUser } from "../users.slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { User } from "../users.interfaces";
import { PlainPanel } from "../../../common/Panel/Panel";
import { Avatar, Box } from "@material-ui/core";
import DeleteDialog from "../../../common/Dialog/DeleteDialog";
import useUserStyles from "./UserTable.styles";
import { defaultAvatar } from "../../../../constants/user.constants";

function imgError(e: any) {
  e.onError = "";
  e.target.src = defaultAvatar;
  return true;
}
export const UserTable = () => {
  const dispatch = useAppDispatch();
  const users = useAppSelector<User[]>(selectUsers);
  const classes = useUserStyles();

  const dataGridUsers = JSON.parse(JSON.stringify(users));

  useEffect(() => {
    dispatch(setLoading(true));
    const fetchUsers = async () => {
      const users = await getUsers();
      dispatch(usersFetched(users));
    };
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cellRender = (user: any) => (
    <img
      alt="Avatar"
      width={50}
      height={50}
      style={{ borderRadius: 50 }}
      src={user.data.image}
      onError={imgError}
    />
  );
  const TagBoxComp = ({ data }: any) => {
    const onValueChanged = (e: any) => {
      data.setValue(e.value);
    };
    const onSelectionChanged = () => {
      data.component.updateDimensions();
    };
    return (
      <TagBox
        valueExpr="id"
        displayExpr="role"
        dataSource={privileges}
        maxDisplayedTags={3}
        items={privileges}
        onValueChanged={onValueChanged}
        onSelectionChanged={onSelectionChanged}
        defaultValue={data.value}
      />
    );
  };

  let applyFilterTypes = [
    {
      key: "auto",
      name: "Immediately",
    },
    {
      key: "onClick",
      name: "On Button Click",
    },
  ];
  let state = {
    showFilterRow: true,
    showHeaderFilter: true,
    currentFilter: applyFilterTypes[0].key,
  };
  const priviliegeCellTemplate = (container: any, options: any) => {
    var noBreakSpace = "\u00A0",
      text = (options.value || [])
        .map((i: any) => privileges[Math.min(i, 2)].role)
        .join(", ");
    container.textContent = text || noBreakSpace;
    container.title = text;
  };
  const [memberToDelete, setMemberToDelete] = React.useState<User>();
  const handleOnFunctionClose = () => {
    setMemberToDelete(undefined);
  };

  const handleDelete = () => {
    memberToDelete &&
      deletetUser(memberToDelete).then(() =>
        dispatch(deleteUser(memberToDelete))
      );
    setMemberToDelete(undefined);
  };

  const DeleteDetails = () => {
    return (
      <>
        <Box className={classes.midStyles}>
          <Box className={classes.avatarPadding}>
            <Avatar />
          </Box>
          <Box>
            <Box>{memberToDelete?.name}</Box>
            <Box>{memberToDelete?.username}</Box>
            <Box>{memberToDelete?.number}</Box>
          </Box>
        </Box>
      </>
    );
  };
  return (
    <Box>
      <DeleteDialog
        details={DeleteDetails}
        open={!!memberToDelete}
        onCancelClose={handleOnFunctionClose}
        onConfirmClose={handleDelete}
      />
      <PlainPanel>
        <DataGrid
          showBorders={true}
          dataSource={dataGridUsers}
          onRowUpdated={(e) => {
            const { id, active, privileges } = e.data;
            updateUser({ id, active, privileges });
          }}
        >
          <HeaderFilter visible={state.showHeaderFilter} />

          <Export enabled={true} />
          <Editing mode="cell" allowUpdating={true} />
          <SearchPanel visible={true} highlightCaseSensitive={true} />
          <Column
            caption="Avatar"
            cellRender={cellRender}
            allowEditing={false}
          />
          <Column dataField="name" caption="Name" allowEditing={false} />
          <Column
            dataField="username"
            caption="Username"
            allowEditing={false}
          />
          <Column
            dataField="number"
            caption="Phone number"
            allowEditing={false}
          />
          <Column
            dataField="email"
            caption="Email Adress"
            allowEditing={false}
          />
          <Column
            dataField="dateOfBirth"
            dataType="date"
            format="dd/MM/yyyy"
            caption="Date Of Birth"
            allowEditing={false}
          />

          <Column
            allowEditing={true}
            dataField="active"
            caption="Status"
            width={150}
          >
            <Lookup dataSource={statuses} displayExpr="name" valueExpr="id" />
          </Column>
          <Column caption="Company" dataField="company" allowEditing={false} />
          <Column
            dataField="privileges"
            caption="Privileges"
            editCellComponent={TagBoxComp}
            cellTemplate={priviliegeCellTemplate}
            width={200}
            allowSorting={false}
          />
          <Column type="buttons">
            <Button
              icon="trash"
              hint="Delete"
              onClick={(e: any) => setMemberToDelete(e.row.data)}
            />
          </Column>
        </DataGrid>
      </PlainPanel>
    </Box>
  );
};
