import { Box } from "@material-ui/core"
import { styled } from "@material-ui/core/styles"
import { colors, theme } from "../../../theme/theme"
const Line = styled(Box)({
  borderTop: "1px solid #dfdfdf",
})

const Container = styled(Box)({
  display: "flex",
  flexDirection: "row",
  backgroundColor: colors.backgroundGrey,
  height: "100%",
})
const MainSection = styled(Box)({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
  marginRight: theme.spacing(3),
})

const SideSection = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "start",
})

const styles = {
  Line,
  Container,
  MainSection,
  SideSection,
}

export default styles
