import React from "react";
import { Box } from "@material-ui/core";
import DataGrid, {
  SearchPanel,
  Sorting,
  Column,
  Export,
  Paging,
  Editing,
} from "devextreme-react/data-grid";
import useEventTableStyles from "./EventsTable.styles";
import { Event } from "../../Events/events.interfaces";
import { PlainPanel } from "../../../common/Panel/Panel";
import { LoadPanel } from "devextreme-react";

interface EventsTableProps {
  events: Event[];
  isLoading: boolean;
  height?: number | string;
}

const defaultCalendarIcon = "/img/calendar.jpg";

function imgError(e: any) {
  e.onError = "";
  e.target.src = defaultCalendarIcon;
  return true;
}

const EventsTable: React.FC<EventsTableProps> = ({ events, isLoading, height }) => {
  const classes = useEventTableStyles();

  const cellRender = (user: any) => (
    <img
      alt="Event"
      width={50}
      height={50}
      style={{ borderRadius: 50 }}
      src={user.data.image || defaultCalendarIcon}
      onError={imgError}
    />
  );
  const cellOrganiser = (event: any) => (
    <img
      alt="Event"
      width={50}
      height={50}
      style={{ borderRadius: 50 }}
      src={event.data.organiserImage || defaultCalendarIcon}
      onError={imgError}
    />
  );

  return (
    <>
      <Box className={classes.eventText}>Event reports</Box>

      <PlainPanel>
        <DataGrid
          height={height}
          id="gridContainer"
          dataSource={events}
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnAutoWidth={true}
        >
          <LoadPanel visible={isLoading} />
          <Editing mode="row" />
          <Paging enabled={true} />
          <Sorting mode="multiple" />
          <SearchPanel visible={true} width={240} placeholder="Search..." />
          <Export enabled={true} />
          <Column caption="Event" cellRender={cellRender} dataField="image" />
          <Column
            caption="EventName"
            allowSorting={true}
            dataField="eventName"
            width=" 200px"
          />
          <Column
            caption="Location"
            allowSorting={true}
            dataField="fullLocation"
            width=" 200px"
          />
          <Column caption="Catagory" dataField="category" width=" 200px" />
          <Column
            caption="Activity Date"
            allowSorting={true}
            dataType="date"
            format="dd/MM/yyyy"
            dataField="eventStartDate"
          />
          <Column
            caption="Orginiser"
            dataField="organiserImage"
            cellRender={cellOrganiser}
            width=" 200px"
          />
          <Column
            caption="Orginisers Name"
            dataField="organiserName"
            width=" 115px"
          />
          <Column caption="Status" dataField="online" />
        </DataGrid>
      </PlainPanel>
    </>
  );
};

export default EventsTable;
