import format from "date-fns/format";
import { dateFormat } from "../constants/date.constants";

interface DateRage {
  fromDate?: Date;
  toDate?: Date;
}

export const getDateRange = ({fromDate, toDate}: DateRage) => {
return !!fromDate && !!toDate
  ? {
      fromDate: format(fromDate, dateFormat.ISO),
      toDate: format(toDate, dateFormat.ISO),
    }
  : undefined;
}