import { createTheme, makeStyles } from "@material-ui/core/styles";
import green from "@material-ui/core/colors/green";

export const colors = {
  blue: "#1f78b4",
  red: "#d10000",
  redOpacity: (opacity) => `rgba(232,128,128,${opacity})`,
  white: "#ffffff",
  grey: "#9e9e9e",
  greyOpacity: (opacity) => `rgba(158,158,158,${opacity})`,
  backgroundGrey: "#F5F7FA",
};

export const border = {
  border: "1px solid #cbd2d6;",
  borderRadius: "6px",
  boxShadow: "0px 1px 4px 0px rgb(0 0 0 / 8%)",
};

export const theme = createTheme({
  typography: {
    hint: {
      color: "#cacaca",
    },
  },
  palette: {
    primary: {
      main: "#0065b0",
    },
    secondary: {
      main: green[500],
    },
  },
  props: {
    MuiButton: {
      size: "large",
    },
    MuiTab: {
      color: "red",
    },
  },
});

export const useCustomTypography = makeStyles((theme) => ({
  hint: theme.typography.hint,
}));
