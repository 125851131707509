import { combineReducers } from "redux";

import users from "../components/pages/UserPage/users.slice";
import events from "../components/pages/Events/events.slice";
import home from "../components/pages/HomePage/home.slice";
import config from "store/slices/config.slice";

export const rootReducer = combineReducers({
  users,
  events,
  home,
  config
});
