import { makeStyles } from "@material-ui/core/styles";
import { theme } from "../../../../theme/theme";

const useEventTableStyles = makeStyles({
  container: {
    maxHeight: "100px",
  },
  eventText: {
    fontWeight: 1000,
    fontSize: 22,
    textAlign: "center",
    marginBottom: theme.spacing(3),
  },
});

export default useEventTableStyles;
