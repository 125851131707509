import React, { useEffect, useState } from "react";
import { Event } from "../Events/events.interfaces";
import { eventsService } from "../Events/events.service";
import EventsTable from "./components/EventsTable";

export const Events = () => {
	const [events, setEvents] = useState<Event[]>([]);
	const [isLoading, setIsLoading] = useState(false); 
	useEffect(() => {
		const fetchEvents = async () => {
			const eventsData = await eventsService.fetchEvents();
			setEvents(eventsData);
			setIsLoading(true);
		};
		fetchEvents();
	}, []);

	return (
		<>
			<EventsTable isLoading={isLoading}  events={events} />
		</>
	);
};
