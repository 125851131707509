interface Menu {
  label: string;
  path: string;
  disabled?: boolean;
}

export const sidePanelMenu: Menu[] = [
  { label: "Dashboard", path: "/" },
  { label: "Members", path: "/users" },
  { label: "Event reports", path: "/event-report", },
  { label: "Acounts", path: "", disabled: true },
  { label: "Logout", path: "/login" },
];
