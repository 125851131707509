import { makeStyles } from "@material-ui/core/styles";
import { colors } from "../../../theme/theme";

const useDashboardCriteriaStyles = makeStyles({
  dateButton: {
    backgroundColor: colors.white,
    textTransform: "capitalize",
  },
});

export default useDashboardCriteriaStyles;
