import api from "../../../api/api.conf";
import { API_ROUTES } from "../../../api/routes.constants";
import { defaultAvatar } from "../../../constants/user.constants";
import { User } from "./users.interfaces";

export const getUsers = async (): Promise<User[]> => {
  const { data } = await api.get("members");
  return data.map((user: User) => ({
    ...user,
    image: user.image || defaultAvatar,
  }));
};

export const updateUser = async (member: Partial<User>) => {
  const update = { active: member.active, privileges: member.privileges };
  const { data } = await api.patch(
    `${API_ROUTES.MEMBERS.BASE}${member.id}`,
    update
  );
  return data;
};

export const deletetUser = async (member: Partial<User>): Promise<any> => {
  await api.delete(`${API_ROUTES.MEMBERS.BASE}${member.id}`);
};
