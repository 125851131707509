import React from "react"
import { theme } from "../../../../theme/theme"
import { PlainPanel } from "../../../common/Panel/Panel"
import { DateSelectionRange } from "../dashboard.interfaces"
import DateRangePicker from "../../../common/DatePicker/DateRangePicker"

interface DashboardCriteriaProps {
  dateSelectionRange: DateSelectionRange
  onRangeSelection: (range: any) => void
}

const DashboardCriteria: React.FC<DashboardCriteriaProps> = ({
  dateSelectionRange,
  onRangeSelection,
}) => {
  return (
    <PlainPanel
      style={{
        padding: theme.spacing(2),
        textAlign: "right",
      }}
    >
      <DateRangePicker
        dateSelectionRange={dateSelectionRange}
        onRangeSelection={onRangeSelection}
      />
    </PlainPanel>
  )
}

export default DashboardCriteria
