import React from "react";
import { colors } from "../../../theme/theme";

const BreakLine = () => {
	return (
		<div
			style={{
				borderBottom: `1px solid ${colors.grey}`,
				margin: "1rem",
			}}
		/>
	);
};

export default BreakLine;
