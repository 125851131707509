import React from "react"
import { DateRangePicker as ReactDateRangePicker } from "react-date-range"
import { Button, Popover } from "@material-ui/core"
import useStyles from "./DateRangePicker.styles"
import { format } from "date-fns"
import { dateFormat } from "../../../constants/date.constants"
import { DateSelectionRange } from "../../pages/HomePage/dashboard.interfaces"
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"

interface DateRangePickerProps {
  dateSelectionRange: DateSelectionRange
  onRangeSelection: (range: any) => void
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({
  dateSelectionRange,
  onRangeSelection,
}) => {
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <>
      <Button
        className={classes.dateButton}
        disableElevation
        variant="contained"
        onClick={handleClick}
      >
        {format(dateSelectionRange.startDate, dateFormat.READABLE)}
        {" - "}
        {format(dateSelectionRange.endDate, dateFormat.READABLE)}
        <ArrowDropDownIcon />
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <ReactDateRangePicker
          ranges={[dateSelectionRange]}
          onChange={(e: any) => onRangeSelection(e.selection)}
        />
      </Popover>
    </>
  )
}

export default DateRangePicker
