import React from "react";
import { useHistory } from "react-router-dom";
import { TextField } from "mui-rff";
import Button from "@material-ui/core/Button";
import { Form } from "react-final-form";
import { Box, Typography } from "@material-ui/core";
import { useLoginStyles } from "./LoginPage.styles";
import authService from "./auth.service";
import useBrand from "hooks/useBrand";

interface LoginForm {
  email: string;
  password: string;
}

const LoginPage = () => {
  const {brandImage}  = useBrand();

  const classes = useLoginStyles();
  const organisationTitle = "SouthWark Dioceses";
  const subTitle = "Event Hub";

  const forgotPasswordText = "Forgot Password";
  const loginButtonText = "Connect";

  const history = useHistory();

  const onSubmit = async ({ email, password }: LoginForm) => {
    const authenticated = await authService.login(email, password);
    authenticated && history.push("/");
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.leftConatiner}>
        <Box className={classes.brandContainer}>
          <Box className={classes.logo}>
            <img src={brandImage} alt="Brand" />
          </Box>
          <Box>
            <Box className={classes.titleText}> {organisationTitle} </Box>
            <Box className={classes.subTitle}>{subTitle}</Box>
          </Box>
        </Box>
      </Box>

      <Box className={classes.rightConatiner}>
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className={classes.form}>
              <TextField
                className={classes.input}
                name="email"
                label="Email"
                type="email"
                variant="outlined"
                margin="dense"
              />
              <TextField
                name="password"
                className={classes.input}
                label="Password"
                variant="outlined"
                type="password"
                margin="dense"
              />
              <Button
                fullWidth
                variant="contained"
                color="primary"
                type="submit"
              >
                {loginButtonText}
              </Button>
            </form>
          )}
        />
        <Typography className={classes.forgottonPassword}>
          {forgotPasswordText}
        </Typography>
      </Box>
    </Box>
  );
};

export default LoginPage;
