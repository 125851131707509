import { Observable } from "rxjs";
import { filter, mapTo } from "rxjs/operators";
import { usersFetched } from "./users.slice";

type UsersFetchedType = ReturnType<typeof usersFetched>;

export const userFetchedEpic = (action$: Observable<UsersFetchedType>) =>
  action$.pipe(
    filter((action) => usersFetched.match(action)),
    mapTo({ type: "PONG" })
  );

const usersEpic = [userFetchedEpic];

export default usersEpic;
