import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Event } from "./events.interfaces";

export type EventRecords = Record<string, Event>;

export interface EventsState {
  byId: EventRecords;
  list: Event[];
  isLoading: boolean;
}

const initialState: EventsState = {
  byId: {},
  list: [],
  isLoading: false,
};

export const eventSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    eventsFetched: (state, action: PayloadAction<Event[]>) => {
      state.list = action.payload;
      state.isLoading = false;
    },
  },
});

export const { setLoading, eventsFetched } = eventSlice.actions;

export default eventSlice.reducer;
