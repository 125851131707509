import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Config } from "services/config/config.interfaces";

export interface ConfigState {
  company: number;
}

const initialState: ConfigState = {
  company: 0,
};

export const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    fetchConfig: (state, action) => {},
    configFetched: (state, action: PayloadAction<Config>) => {
      state.company = action.payload.company;
    } 
  },
});


export const { fetchConfig, configFetched } = configSlice.actions;

export default configSlice.reducer;
