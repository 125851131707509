import { makeStyles } from "@material-ui/core/styles"
import { colors } from "../../../theme/theme"

const useTabPanelStyles = makeStyles({
  tabContainer2: {
    display: "flex",
    flexDirection: "column",
  },
  tabContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: colors.white,
  },
  tab: {
    flexGrow: 1,
  },
  tabContent: {
    backgroundColor: "white",
  },
  line: {},
})

export default useTabPanelStyles
