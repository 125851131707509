import React from "react"
import MuiTabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import { TabConfig } from "./TabPanel.interfaces"
import Box from "@material-ui/core/Box"
import useTabPanelStyles from "./TabPanel.styles"
import BreakLine from "../Layout/BreakLine"

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

export const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props

  return (
    <div hidden={value !== index} {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}

interface TabPanelsProps {
  tabConfig?: TabConfig[]
  styles?: object
}

export const TabPanels: React.FC<TabPanelsProps> = ({ tabConfig = [] }) => {
  const [value, setValue] = React.useState(0)
  const classes = useTabPanelStyles()

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
  }

  return (
    <>
      <MuiTabs
        className={classes.tabContainer}
        value={value}
        onChange={handleChange}
        variant="fullWidth"
        indicatorColor="primary"
      >
        <BreakLine />
        {tabConfig.map((tab, index) => {
          const { icon: Icon } = tab
          return (
            <Tab
              disabled={tab.disabled}
              key={index}
              label={tab.title}
              className={classes.tab}
              icon={<Icon />}
            />
          )
        })}
      </MuiTabs>
      <Box className={classes.tabContent}>
        {tabConfig.map((tab, index) => {
          const { TabContent } = tab
          return (
            <TabPanel key={index} value={value} index={index}>
              <TabContent />
            </TabPanel>
          )
        })}
      </Box>
    </>
  )
}
