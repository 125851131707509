import { format } from "date-fns";
import api from "../../../api/api.conf";
import { API_ROUTES } from "../../../api/routes.constants";
import qs from "qs";
import {
	ChartableEvent,
	ChartableEventRecord,
	Event,
} from "./events.interfaces";
import { getDateRange } from "../../../helpers/date.helpers";

class EventsService {
	async fetchEvents(fromDate?: Date, toDate?: Date): Promise<Event[]> {

		const dateRange = getDateRange({fromDate, toDate});

		const { data } = await api.get(
			`${API_ROUTES.EVENTS.BASE}${API_ROUTES.EVENTS.STATS}?${qs.stringify(
				dateRange
			)}`
		);
		return data;
	}

	mapToChartableEvents(events: Event[]): ChartableEvent[] {
		const chartableRecords = events.reduce(
			(acc: ChartableEventRecord, { eventStartDate, online }, index) => {
				const dateKey = format(new Date(eventStartDate), "yyyy-MM");
				const dateString = format(new Date(eventStartDate), "MMM yyyy");

				const incrementKey = online ? "online" : "offline";

				const record = acc[dateKey] || {
					month: dateString,
					online: 0,
					offline: 0,
				};

				return {
					...acc,
					[dateKey]: {
						...record,
						[incrementKey]: record[incrementKey] + 1,
					},
				};
			},
			{}
		);

		return Object.keys(chartableRecords)
			.map((key) => chartableRecords[key])
			.filter((record) => !!record.month);
	}
}

const eventsService = new EventsService();

Object.freeze(eventsService);

export { eventsService };
