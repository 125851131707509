import { api } from "api/api.conf";
import { API_ROUTES } from "api/routes.constants";
import { AxiosObservable } from "axios-observable/dist/axios-observable.interface";
import { Config } from "./config.interfaces";

export class ConfigService {
  getConfig (): AxiosObservable<Config> {
    return api.get(API_ROUTES.CONFIG.BASE);
  }
}

const configService = new ConfigService();

Object.freeze(configService);

export default configService;
