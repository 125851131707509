import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { ThemeProvider } from "@material-ui/styles";
import { store } from "./store/store";
import LoginPage from "./components/pages/auth/LoginPage";
import { theme } from "./theme/theme";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { DashBoard } from "./components/pages/HomePage";
import { Events } from "./components/pages/Events";
import { UserPage } from "./components/pages/UserPage";
import BaseLayout from "./layout/BaseLayout/BaseLayout";
import { useAppDispatch } from "store/hooks";
import { RouteWrapper } from "./components/common/RouteWrapper";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.material.blue.light.compact.css";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { fetchConfig } from "store/slices/config.slice";


const Init = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchConfig({}));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>
}

const App = () => {

  return (
    <Router>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <Init />
          <Switch>
            <Route path="/login" component={LoginPage} />
            <RouteWrapper
              path="/users"
              component={UserPage}
              layout={BaseLayout}
            />
            <RouteWrapper
              path="/event-report"
              component={Events}
              layout={BaseLayout}
            />
            <RouteWrapper path="/" component={DashBoard} layout={BaseLayout} />
          </Switch>
        </ThemeProvider>
      </Provider>
    </Router>
  );
};

export default App;
