import { createSelector } from "reselect";
import { State } from "../../../store/store.interfaces";
import { HomeState } from "./home.slice";

export const usersSelector = (state: State) => state.home;

export const selectStatistics = createSelector(
	usersSelector,
	(home: HomeState) => home.statisticsByName
);

