import { makeStyles } from "@material-ui/core/styles";
import { theme } from "../../../theme/theme";

const useStatsBriefStyles = makeStyles({
  iconContainer: {
    color: ({ difference }) => 
      difference === 0 ? 'orange' : difference > 0 ? 'green' : 'red'
    ,
    display: 'flex',
    alignItems: 'center',
    transform: ({ difference }) => difference > 0 ? 'rotate(180deg)' : undefined
  },
  statsListContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    "& > div:first-child": {
      paddingLeft: theme.spacing(3),
    },
    "& > div:last-child": {
      paddingRight: theme.spacing(3),
    },
  },
  container: {
    cursor: 'default',
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    maxWidth: "400px",
    opacity: ({ disabled }: any) => {
      return disabled ? 0.1 : 1;
    },
  },
  title: {
    color: "grey",
    fontSize: 15,
    textTransform: 'capitalize',
    marginBottom: "-4px",
  },
  iconText: {
    display: "flex",
    flexDirection: "row",
    fontSize: 25,
    fontWeight: 500,
  },
  info: {
    color: "grey",
    fontSize: "9px",
  },
  icon: {
    color: "green",
  },
  iconTextContainer: {
    marginRight: ".5rem",
  },
});

export default useStatsBriefStyles;
