import React from "react";
import { Panel } from "../../common/Panel/Panel.interfaces";
import { TabConfig } from "../../common/TabPanel/TabPanel.interfaces";
import EventsChart from "../Events/components/EventsChart";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import MessageIcon from "@material-ui/icons/Message";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import { ChartableEvent } from "../Events/events.interfaces";
import ArrowDropDownCircleRoundedIcon from "@material-ui/icons/ArrowDropDownCircleRounded";

const idleElement = {
  id: 5,
  title: "IDLE",
  icon: ArrowDropDownCircleRoundedIcon,
  iconText: "000",
  sub: "Coming soon...",
  disabled: true,
};

export const LastHourPanelconfig = [
  {
    title: "hi",
    icon: ArrowDropDownCircleRoundedIcon,
    iconText: "hi",
    sub: "hi",
  },
];
export const panelConfig: Panel[] = [
  {
    title: "Activity",
    content:
      " Cras molestie erat enim, egestas accumsan nisi viverra in. Aenean pretium blandit enim volutpat tincidunt. Aenean egestas cursus mi, non pretium lacus laoreet sit amet. Suspendisse congue nulla in magna vestibulum, eget porttitor sapien placerat. Integer commodo lectus ac risus rutrum blandit. Cras maximus est ut risus scelerisque, iaculis rhoncus lectus efficitur. Mauris ullamcorper dictum tempus. Vivamus vitae mi id leo accumsan gravida. Vestibulum eu risus vitae leo tempor pellentesque a eget ex. Curabitur feugiat ipsum id venenatis ornare. Proin venenatis et velit at facilisis. Morbi nunc risus, sagittis sed rutrum a, finibus quis elit. Nunc cursus eget nibh id vulputate. Sed quam dolor, convallis vitae aliquam eget, commodo eget mi. Vivamus iaculis lorem eget maximus maximus. Nam eleifend ante quam. Aliquam commodo, nunc id pharetra consectetur, ex ex sagittis enim, in egestas ipsum nibh at massa. Integer dictum, neque vitae volutpat blandit, enim turpis fermentum purus, id sodales purus sapien in orci. Suspendisse posuere volutpat mattis. Suspendisse vitae justo purus.",
  },
  {
    title: "Upcoming Events",
    content:
      "  Cras molestie erat enim, egestas accumsan nisi viverra in. Aenean pretium blandit enim volutpat tincidunt. Aenean egestas cursus mi, non pretium lacus laoreet sit amet. Suspendisse congue nulla in magna vestibulum, eget porttitor sapien placerat. Integer commodo lectus ac risus rutrum blandit. Cras maximus est ut risus scelerisque, iaculis rhoncus lectus efficitur. Mauris ullamcorper dictum tempus. Vivamus vitae mi id leo accumsan gravida. Vestibulum eu risus vitae leo tempor pellentesque a eget ex. Curabitur feugiat ipsum id venenatis ornare. Proin venenatis et velit at facilisis. Morbi nunc risus, sagittis sed rutrum a, finibus quis elit. Nunc cursus eget nibh id vulputate. Sed quam dolor, convallis vitae aliquam eget, commodo eget mi. Vivamus iaculis lorem eget maximus maximus. Nam eleifend ante quam. Aliquam commodo, nunc id pharetra consectetur, ex ex sagittis enim, in egestas ipsum nibh at massa. Integer dictum, neque vitae volutpat blandit, enim turpis fermentum purus, id sodales purus sapien in orci. Suspendisse posuere volutpat mattis. Suspendisse vitae justo purus.",
  },
];

export const dashboardTabConfig = (events: ChartableEvent[]): TabConfig[] => [
  {
    title: "Events",
    icon: CalendarTodayIcon,
    disabled: false,
    TabContent: () => <EventsChart events={events} />,
  },
  {
    title: "Users",
    icon: PeopleAltIcon,
    disabled: true,
    TabContent: () => <EventsChart events={events} />,
  },
  {
    title: "Messages",
    icon: MessageIcon,
    disabled: true,
    TabContent: () => <EventsChart events={events} />,
  },
];
export const statsBriefConfig = [
  {
    ...idleElement,
    id: 4,
  },
  {
    ...idleElement,
    id: 5,
  },
  {
    ...idleElement,
    id: 6,
  },
  {
    ...idleElement,
    id: 7,
  },
  {
    ...idleElement,
    id: 8,
  },
];

export const STATISTIC_CONFIG = {
  USERS_ACTIVE: 'active-users',
  USERS_INACTIVE: 'inactive-users',
  REPEAT_EVENTS: 'repeat-events',
  NEW_EVENTS: 'new-events',
} as const;