import { Box } from "@material-ui/core"
import React, { useState } from "react"
import BreakLine from "../Layout/BreakLine"
import usePanelStyles from "./Panel.styles"

interface PanelProps {
  children: React.ReactNode | React.ReactNodeArray
  title: string
  defaultHeight?: string | number
  defaultWidth?: string | number
}

const Panel = ({
  children,
  title,
  defaultHeight = "300px",
  defaultWidth = "250px",
}: PanelProps) => {
  const [seeMoreActive, setSeeMoreActive] = useState(true)
  const styles = usePanelStyles({ seeMoreActive, defaultHeight, defaultWidth })
  return (
    <Box className={styles.Container}>
      <Box className={styles.TitleText}>{title}</Box>
      <BreakLine />
      <Box className={styles.Content}>{children}</Box>
      <Box
        className={styles.SeeMoreText}
        onClick={() => setSeeMoreActive(!seeMoreActive)}
      >
        {seeMoreActive ? "See More" : "See Less"}
      </Box>
    </Box>
  )
}

interface PlainPanelProps {
  children: any
  style?: object
}

export const PlainPanel = ({ children, style }: PlainPanelProps) => {
  const styles = usePanelStyles()

  return (
    <Box className={styles.plainRoot} style={style}>
      <Box>{children}</Box>
    </Box>
  )
}

export default Panel
