import { makeStyles } from "@material-ui/core/styles";

const useEventChartStyles = makeStyles({
  container: {
    display: "flex",
    justifyContent: "center",
  },
  chartWrapper: {
    width: "95%",
  },
});

export default useEventChartStyles;
