import { StatsConfig } from "../../common/StatsBrief/StatsBrief.interfaces";
import { StatisticName } from "./dashboard.interfaces";
import { StatisticsRecord } from "./home.slice";
import ArrowDropDownCircleRoundedIcon from "@material-ui/icons/ArrowDropDownCircleRounded";
import SwapHorizontalCircleIcon from '@material-ui/icons/SwapHorizontalCircle';

class StatisticsService {
  transformStatisticToBrief(statistics: StatisticsRecord): StatsConfig[]  {

    const differenceLabel = (diff: number) => {
      if (diff === 0) {
        return 'There has been no change';
      }

        return `${diff ? 'Increased' : 'Decreased'} by ${diff < 0 ? diff * -1 : diff}`;
    };

    return Object.keys(statistics).map((key: StatisticName) => {
      const stat = statistics[key];
      return {
        id: stat.id,
        icon: stat.difference !== 0 ? ArrowDropDownCircleRoundedIcon: SwapHorizontalCircleIcon,
        iconText: stat.value,
        difference: stat.difference,
        sub: differenceLabel(stat.difference),
        title: stat.label,
      }
    });
  }
}

const statisticsService = new StatisticsService();

Object.freeze(statisticsService);

export { statisticsService };