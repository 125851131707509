import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Statistic, StatisticName } from "./dashboard.interfaces";

export type StatisticsRecord = Record<StatisticName,Statistic>;

export interface HomeState {
  statisticsByName: StatisticsRecord | {};
  isLoading: boolean;
}

const initialState: HomeState = {
  statisticsByName: {},
  isLoading: false,
};

export const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    statisticsFetched: (state, action: PayloadAction<StatisticsRecord>) => {
      state.statisticsByName = action.payload;
      state.isLoading = false;
    },
    fetchStatistics: (state, action) => {} 
  },
});

// !!fromDate && !!toDate
// ? {
//     fromDate: format(fromDate, dateFormat.ISO),
//     toDate: format(toDate, dateFormat.ISO),
//   }
// : undefined;

export const { setLoading, statisticsFetched, fetchStatistics } = homeSlice.actions;

export default homeSlice.reducer;
