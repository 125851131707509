import axios from "axios";
import axiobs from  'axios-observable';
import Cookies from "universal-cookie";
import { AUTH_TOKEN } from "../constants/cookies.constants";
import { ROUTES } from "./routes.constants";

const cookies = new Cookies();

const instance = axios.create({
  baseURL: process.env.REACT_APP_SENSEADMIN_API_HOST,
  timeout: 1000,
});

// Add a request interceptor
instance.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    config.headers = {
      Authorization: `Bearer ${cookies.get(AUTH_TOKEN)}`,
    };
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    if (error.message.includes(401)) {
      console.log("401 unauthenticated: ", error.message);

      window.location.href = ROUTES.AUTH.LOGIN;
    }

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

// Observable
const instanceObs = axiobs.create({
  baseURL: process.env.REACT_APP_SENSEADMIN_API_HOST,
  timeout: 1000,
});

// Add a request interceptor
instanceObs.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    config.headers = {
      Authorization: `Bearer ${cookies.get(AUTH_TOKEN)}`,
    };
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

instanceObs.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    if (error.message.includes(401)) {
      console.log("401 unauthenticated: ", error.message);

      if (window.location.href.indexOf(ROUTES.AUTH.LOGIN) === -1) {
        window.location.href = ROUTES.AUTH.LOGIN;
      }
    }

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export default instance;

export { instanceObs as api };
